import * as React from 'react'
import { Link, graphql } from 'gatsby'
import Layout2 from '../../components/layout2'
import Seo2 from '../../components/seo2'

const IndexPage = () => {
  return (
    <Layout2 pageTitle="Technology">
      <p>Here are the most useful pieces of technology I use.</p>
      <p>Here are my technology hobbies.</p>
    </Layout2>
  )
}

export const Head = () => <Seo2 title="Technology" />

export default IndexPage